<template>
  <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 15% 5%' : 'padding: 6% 5%'
    ">
    <PageBreadcrumbs :data="breadcrumbs" />
    <vs-row justify="center">
      <h1>Designer Dress Sets</h1>
      <span class="material-symbols-outlined"> female </span>
    </vs-row>
    <Products :products="dressSets" />
  </div>
</template>

<script>
export default {
  name: 'Bottoms',
  components: {
    PageBreadcrumbs: () => import('../components/PageBreadcrumbs.vue'),
    Products: () => import('../components/Products.vue'),
  },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Dress Sets',
        disabled: true,
      },
    ],
  }),
  metaInfo() {
    return {
      meta: [
        {
        name: 'description',
        content: 'Discover Bengalina’s coordinated dress sets for effortless, chic style. These co-ords blend traditional charm with modern trends, perfect for any event.',
        },
        {
          property: 'og:title',
          content: 'Stylish Dress Sets & Co-ords | Bengalina - Dhaka, Bangladesh',
        },
        {
          property: 'og:description',
          content: 'Effortless style with Bengalina’s co-ords collection. Discover dress sets combining elegance and convenience, ideal for all occasions.',
        },
        {
          name: 'keywords',
          content: 'dress sets Dhaka, co-ords Bangladesh, coordinated outfits Dhaka, Bengalina fashion, traditional co-ords, modern dress sets Bangladesh, stylish dress sets, tops, dresses, bottoms, co-ords, kameez.',
        },
      ]
    }
  },
  computed: {
    dressSets() {
      return this.$store.state.dressSets;
    },
  },
};
</script>
